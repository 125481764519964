import baguetteBox  from './vendors/baguetteBox/baguetteBox.min.js';
import { toggleClass } from './mobileNavigation.js';


var isIE = /*@cc_on!@*/false || !!document.documentMode




document.addEventListener("DOMContentLoaded", function(){
  'use strict';
  if(!isIE){
    toggleClass('.nav-trigger.navtrigger-open','.nav-open .nav-trigger');
  }
  // load ie11 css vars polyfill
  if(isIE) {
     var s = document.createElement('script');
     s.onload = function() {
       cssVars();
     }
     s.src = 'https://unpkg.com/css-vars-ponyfill@1';
     document.getElementsByTagName('head')[0].appendChild(s);
  }

  baguetteBox.run('.ce_gallery, .ce_image a[data-lightbox], .ce_text a[data-lightbox] ', {
		animation: 'fadeIn',
    noScrollbars: false
	});

});
